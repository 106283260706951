<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <nav class="bg-light">
          <ul class="nav nav-tabs nav-fill w-100" id="nav-tab" role="tablist">
            <li class="nav-item">
              <router-link
                :to="{name: 'VesselAccidentIncidentHistory' }"
                tag="a"
                class="nav-link border border-bottom-0"
                :class="{'active text-white': isActiveRoute('VesselAccidentIncidentHistory')}"
                role="tab"
                data-toggle="list"
              >
                Accident Incident History
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{name: 'VesselPscInspectionHistory'}"
                tag="a"
                class="nav-link border border-bottom-0"
                :class="{'active text-white': isActiveRoute('VesselPscInspectionHistory')}"
                role="tab"
                data-toggle="list"
              >
               PSC Inspection History
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link border border-bottom-0 disabled">RS Inspection</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{name: 'VesselInternalAuditHistory'}"
                tag="a"
                class="nav-link border border-bottom-0"
                :class="{'active text-white': isActiveRoute('VesselInternalAuditHistory')}"
                role="tab"
                data-toggle="list"
              >
                Internal Audit
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
export default {
  name: "VesselHistory",
  mixins: [vueAppMixin],
}
</script>

<style scoped>
.active{
  background-color: #dc3545 !important;
}
</style>
